// @use "../../assets/Sass/media-queries.scss" as *;
// @include lt-xxl {}

$screen-xs-max: 599px;
// media aliases and breakpoints

$screen-sm-min: 600px;
$screen-sm-max: 959px;

$screen-md-min: 960px;
$screen-md-max: 1279px;

$screen-lg-min: 1280px;
$screen-lg-max: 1370px;

$screen-xl-min: 1371px;
$screen-xl-max: 1680px;

$screen-xxl-min: 1681px;
$screen-xxl-max: 2000px;

// media devices
@mixin xs {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin xxl {
  @media screen and (min-width: #{$screen-xxl-min}) and (max-width: #{$screen-xxl-max}) {
    @content;
  }
}

// media lt queries
@mixin lt-sm {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin lt-md {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lt-xl {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin lt-xxl {
  @media screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// media gt queries
@mixin gt-xs {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin gt-lg {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin gt-xl {
  @media screen and (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
